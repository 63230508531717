import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Partners = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Nuestros partners",
    paragraph: "",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div
            style={{
              height: 50,
              weight: 50,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginBottom: 60,
              flexWrap: 'wrap'
            }}
          >
            <img className="cryptoAvisos" src={require("../../assets/images/viajobien.png")} />
            <img className="cryptoAvisos" src={require("../../assets/images/newells.png")} />
            <img className="cryptoAvisos" src={require("../../assets/images/criptoplace.png")} />
            <img
              className="cryptoAvisos"
              src={require("../../assets/images/cryptoavisos.png")}
            />
            <img className="cryptoAvisos" src={require("../../assets/images/defiant.png")} />
            <img className="cryptoAvisos" src={require("../../assets/images/ditelstore.png")} />
          </div>
        </div>
      </div>
    </section>
  );
};

Partners.propTypes = propTypes;
Partners.defaultProps = defaultProps;

export default Partners;
