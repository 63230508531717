import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import axios from "axios";
import { Link } from "react-scroll";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Exchange = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Exchange",
    paragraph:
      "Durante el Periodo BETA, los precios podrían estar sujetos a cambios Comunicate con nosotros ante cualquier consulta.",
  };

  const [currency, setCurrency] = useState("_ARS");
  const [rates, setRates] = useState([]);
  const url = "https://api.calypso.exchange/v1/public/rates";

  useEffect(async () => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => setRates(data));
  }, []);

  function numberWithCommas(x) {
    return x.toLocaleString("es-AR", {
      minimumFractionDigits: currency === "_ARS" ? 2 : 4,
    });
  }

  // concat to the array 4 objects with the same buyPrice and sellPrice, but one with the pair DAI_ARS, other DAI_USD, other USDC_ARS, other USDC_USD
  const daiUsd = rates
    .filter((data) => data.pair.includes("USDT_USD"))
    .map((rate) => {
      const { buyPrice, sellPrice } = rate;
      return {
        pair: "DAI_USD",
        buyPrice,
        sellPrice,
      };
    });
  const daiArs = rates
    .filter((data) => data.pair.includes("USDT_ARS"))
    .map((rate) => {
      const { buyPrice, sellPrice } = rate;
      return {
        pair: "DAI_ARS",
        buyPrice,
        sellPrice,
      };
    });
  // const busdUsd = rates
  //   .filter((data) => data.pair.includes("USDT_USD"))
  //   .map((rate) => {
  //     const { buyPrice, sellPrice } = rate;
  //     return {
  //       pair: "BUSD_USD",
  //       buyPrice,
  //       sellPrice,
  //     };
  //   });
  // const busdArs = rates
  //   .filter((data) => data.pair.includes("USDT_ARS"))
  //   .map((rate) => {
  //     const { buyPrice, sellPrice } = rate;
  //     return {
  //       pair: "BUSD_ARS",
  //       buyPrice,
  //       sellPrice,
  //     };
  //   });
  const usdcUsd = rates
    .filter((data) => data.pair.includes("USDT_USD"))
    .map((rate) => {
      const { buyPrice, sellPrice } = rate;
      return {
        pair: "USDC_USD",
        buyPrice,
        sellPrice,
      };
    });
  const usdcArs = rates
    .filter((data) => data.pair.includes("USDT_ARS"))
    .map((rate) => {
      const { buyPrice, sellPrice } = rate;
      return {
        pair: "USDC_ARS",
        buyPrice,
        sellPrice,
      };
    })
    .flat();

  const rates2 = rates.concat(
    daiUsd,
    daiArs,
    usdcUsd,
    usdcArs
    // busdUsd,
    // busdArs
  );
  
  // Filter de array to exclude all the pairs that doesn't include USDT, BUSD, DAI or USDC

  const newRates = rates2
    .filter((data) => data?.pair?.includes(currency))
    .filter(
      (data) =>
        data?.pair?.includes("USDT") ||
        data?.pair?.includes("USDC") ||
        data?.pair?.includes("DAI")
    )
    .sort((a, b) => b.pair.localeCompare(a.pair))
    .map((val) => {
      return (
        <div className="tiles-item" data-reveal-delay="200">
          <div style={{ borderRadius: 20 }} className="tiles-item-inner">
            <div
              style={{
                paddingRight: 5,
                paddingLeft: 5,
                paddingBottom: 20,
              }}
              className=""
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ height: 22, width: 22, marginRight: 10 }}
                  src={require(`../../assets/images/${val.pair.substring(
                    0,
                    4
                  )}.png`)}
                />
                <h4>{val.pair.replace("_", "/")}</h4>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <p style={{ marginBottom: 0, fontSize: 14, color: "#fff" }}>
                    Compra a:
                  </p>
                  <p style={{ color: "#fff" }}>
                    ${numberWithCommas(parseFloat(val.sellPrice))}
                  </p>
                </div>
                <div>
                  <p style={{ marginBottom: 0, fontSize: 14, color: "#fff" }}>
                    Vende a:
                  </p>
                  <p style={{ color: "#fff" }}>
                    ${numberWithCommas(parseFloat(val.buyPrice))}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <a
                  className="button button-primary button-margin"
                  color="primary"
                  wideMobile
                  href="#contact"
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 4,
                    height: 35,
                  }}
                >
                  <Link smooth={true} to="contact">
                    Comprá
                  </Link>
                </a>
                <a
                  className="button"
                  color="primary"
                  wideMobile
                  href="#contact"
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 4,
                    height: 35,
                  }}
                >
                  <Link style={{ color: "#000" }} smooth={true} to="contact">
                    Vendé
                  </Link>
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    });

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginBottom: 20,
                backgroundColor: "#25282C",
                borderRadius: 15,
                paddingLeft: 10,
                paddingRight: 10,
                width: "auto",
              }}
            >
              <p
                style={{
                  margin: 10,
                  color: currency === "_ARS" ? "#f37037" : "white",
                  fontWeight: currency === "_ARS" ? 600 : 400,
                  cursor: "pointer",
                  transition: "0.3s",
                }}
                onClick={() => setCurrency("_ARS")}
              >
                ARS
              </p>
              <p
                style={{
                  margin: 10,
                  color: currency === "_USD" ? "#f37037" : "white",
                  fontWeight: currency === "_USD" ? 600 : 400,
                  cursor: "pointer",
                  transition: "0.3s",
                }}
                onClick={() => setCurrency("_USD")}
              >
                USD
              </p>
              {/* <p
                style={{
                  margin: 10,
                  color: currency === "_EUR" ? "#f37037" : "white",
                  fontWeight: currency === "_EUR" ? 600 : 400,
                  cursor: "pointer",
                  transition: "0.3s",
                }}
                onClick={() => setCurrency("_EUR")}
              >
                EUR
              </p> */}
            </div>
          </div>
          <div className={tilesClasses}>
            {rates.length > 1 ? newRates : null}
          </div>
        </div>
      </div>
    </section>
  );
};

Exchange.propTypes = propTypes;
Exchange.defaultProps = defaultProps;

export default Exchange;
