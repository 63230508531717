import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import BlogSection from '../components/sections/BlogSection';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import Exchange from '../components/sections/Exchange';
import Contact from '../components/sections/Contact';
import Partners from '../components/sections/Partners'

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      <Exchange id="exchange"/>
      <BlogSection />
      <Testimonial className="testimonials" id="testimonials" topDivider />
      <Partners />
      <Contact id="contact" />
    </>
  );
}

export default Home;