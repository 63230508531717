import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Contact = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Contacto",
    paragraph:
      "Contactate a través de Telegram con nuestros operadores verificados para comprar o vender criptomonedas con modalidad P2P",
  };

  const telegramUsers = [
    "@SoporteCalypso",
    "@perrovolpe",
    "@Clautm07",
    "@CalypsoExchange",
  ];

  const telegramUsersMap = telegramUsers.map((user) => {
    return (
      <a href={`https://t.me/${user.replace('@', '')}`} target="_blank">
        <p
          style={{
            fontSize: 16,
            display: "flex",
            flexDirection: "row",
            padding: 10,
            borderRadius: 10,
            backgroundColor: "#25282C",
            marginTop: 5,
            marginRight: 5,
            fontWeight: 600
          }}
        >
          {user}
          <img
            style={{ width: 30, height: 30 }}
            src={require("../../assets/images/telegrambadge.png")}
          />
        </p>
      </a>
    );
  });

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              marginBottom: 20,
              flexWrap: "wrap",
              alignSelf: "center",
            }}
          >
            {telegramUsersMap}
          </div>
        </div>
      </div>
    </section>
  );
};

Contact.propTypes = propTypes;
Contact.defaultProps = defaultProps;

export default Contact;
